<template>
  <BasePage>
    <div className="text_wrap">
      <p>Terms of Use and Membership</p>
      <br />

      <p>Last Updated: February 2022</p>
      <br />

      <p></p>
      <br />

      <p>
        Welcome to  ZY Adstech.com (this "Site"). By using this Site, registering
        as a member of  ZY Adstech, participating in the  ZY Adstech Points ("Points"
        or " ZY Adstech Points") membership program (" ZY Adstech Points Program" or
        "Points Program") or participating in any survey or study provided or
        hosted by  ZY Adstech (each, a "Survey" and collectively "Surveys"), you
        agree to these Terms of Use and Membership ("Terms") and confirm that
        you have read and understood our privacy policy ("Privacy Policy").
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Our Privacy Policy sets out how  ZY Adstech collects, uses and stores your
        personal information and a copy can be found here .
      </p>
      <br />

      <p></p>
      <br />

      <p>
        If you do not agree to the Terms and Privacy Policy, do not use this
        Site.
      </p>
      <br />

      <p>
         ZY Adstech reserves the right, in its sole discretion, to change, modify,
        add or remove portions of these Terms, and/or our Privacy Policy, at any
        time. It is your responsibility to check these Terms and our Privacy
        Policy periodically for changes. These Terms were last updated on the
        date at the top of these Terms.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Your continued use of this Site following the posting of such changes
        will mean that you accept and agree to the changes. Provided you comply
        with these Terms,  ZY Adstech grants you a personal, non-exclusive,
        non-transferable, limited privilege to enter and use this Site
      </p>
      <br />

      <p></p>
      <br />

      <p>Eligibility</p>
      <br />

      <p>
        Membership in any  ZY Adstech panel is free and is open to individuals only
        (not corporations or other business entities) that are at least sixteen
        (16) years of age or such other age as required by your geographic
        location. Membership in certain panels may be further restricted based
        on your age or geographic location. If you are less than eighteen (18)
        years of age and win any prize or receive any cash reward through
         ZY Adstech Points or any other  ZY Adstech incentive program, then such award
        will be made to your legal guardian. You are permitted to have only one
        account.
      </p>
      <br />

      <p></p>
      <br />

      <p>Registration and Passwords</p>
      <br />

      <p>
        While you may access this Site without registering for membership in any
         ZY Adstech panel, for an account to be established in your name and any
        Points credited to your account, you must complete the online
        registration form and must provide  ZY Adstech with complete and valid
        contact information, including your full legal name, home address, and
        valid e-mail address, together with all other requested information.
        Your account must have a unique and valid e-mail address and password.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        You agree to provide only accurate, current and complete registration
        information and to keep that information updated in order to maintain
        its accuracy.  ZY Adstech may terminate or suspend your membership and you
        may forfeit your Points if you fail to provide or keep your personal
        information accurate and complete.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        You may use only one (1) password to open and access your account, and
        you may not use another account holder's password or allow anyone else
        to use your password, for any reason. You are solely responsible for the
        security of your user name and password and any activity that occurs
        under your membership account, whether authorized or unauthorized.
         ZY Adstech will not be responsible for any losses incurred through the use
        of your password by a third party, except when unauthorized use is
        directly attributable to the gross negligence or fraud of  ZY Adstech. You
        agree to immediately notify  ZY Adstech of any unauthorized use or breach
        of your member account.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Should you forget your password, you may request an e-mail to reset your
        password using your username so that you can regain use of your account
        and an email will be sent to the e-mail address we hold in your member
        profile.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        By joining as a member of  ZY Adstech's panels, you agree to receive
        invitations to participate in Surveys via e-mail.  ZY Adstech does not
        guarantee that you will receive a certain minimum volume of invitations
        or any invitations at all. Your participation in any Survey and your
        disclosure of any personally identifiable information is completely
        voluntary and so whether you accept or reject any invitations to
        participate in surveys is entirely up to you and we will not penalize
        you for refusing to accept any such invitations.
      </p>
      <br />

      <p></p>
      <br />

      <p>Relationships</p>
      <br />

      <p>
        You are responsible for providing any computer equipment and
        communications services necessary to connect to and access this Site.
        You agree that neither your membership in a  ZY Adstech panel nor your
        completion of any Surveys for  ZY Adstech and its clients create any
        agency, partnership or employment relationship, and that your completion
        of Surveys is strictly as an independent contractor. You also agree the
        Points or other incentives you receive for completing Surveys are the
        only compensation you will receive for your completion of Surveys.
      </p>
      <br />

      <p></p>
      <br />

      <p>Code of Conduct Members:</p>
      <br />

      <p>
         ZY Adstech is an online community dedicated to making your voice heard and
        allowing you to tell leading providers of products and services what you
        think. When you use and post content to the  ZY Adstech Site or our
        community we ask that you observe the following rules:
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Be courteous and respect the opinions of others and behave in a manner
        that supports a safe and comfortable environment for all members.
      </p>
      <br />

      <p>Do not post any of the following:</p>
      <br />

      <p>Personal information (including any financial information)</p>
      <br />

      <p>Material that advocates illegal activity</p>
      <br />

      <p>Political, religious or ideological beliefs.</p>
      <br />

      <p>
        Personal attacks or anything obscene, vulgar, illegal, harmful,
        insulting, threatening, abusive, harassing, defamatory, libelous,
        untrue, misleading or invasive of someone else's privacy (including
        "jokes" that may be misconstrued).
      </p>
      <br />

      <p>
        Advertising and solicitations - do not use any community areas to buy or
        solicit for goods, services, or money, or to advertise or sell products
        or services to others. For example, do not post any advertising,
        referrals, promotional materials, junk mail, "spam", chain letters,
        pyramid schemes or any other form of solicitation.
      </p>
      <br />

      <p>
        Any content that infringes any patent, copyright, trademark, trade
        secret or other intellectual property rights of another. In this regard
        if you do wish to post images on our site please make sure that the
        images you upload are free to use (for example from websites like
        pixabay) or even better, use your own photos and images instead.
      </p>
      <br />

      <p>
        Any statements that express or imply that any actions you take are
        endorsed by  ZY Adstech or our clients.
      </p>
      <br />

      <p>
        Do not take any Survey other than in good faith, such as providing false
        answers or speeding through a survey or answering each question the same
        way.  ZY Adstech reserves the right to withhold awarding Points to you if,
        in  ZY Adstech's reasonable view, your Survey responses are untruthful or
        not well considered.
      </p>
      <br />

      <p>
        Do not use your  ZY Adstech account outside of the country you registered
        in. The surveys we offer are related to the country you signed up in,
        asking questions that are relevant to you. Accessing your account and
        taking surveys out of the country of registration can be seen as
        fraudulent and may result in termination of your membership. This also
        applies if you go on holidays.
      </p>
      <br />

      <p>
        Do not use a VPN, proxy, Tor or any type of masked connection/software.
      </p>
      <br />

      <p>
        Avoid using shared wifi networks. This will allocate you the same IP
        address as other people on the network, that might also use a  ZY Adstech
        account, and this could potentially be flagged as using multiple
        accounts.
      </p>
      <br />

      <p>
        Make sure your device settings reflect the country and language you are
        registered in.
      </p>
      <br />

      <p>
        Do not access or use any part of the Site for anything other than your
        personal, non-commercial use.
      </p>
      <br />

      <p>
        Do not access or use any part of the Site in any way that may violate
        any applicable provincial, federal, or international laws, regulations,
        or other governmental requirements and/or regulations, treaties or
        tariffs.
      </p>
      <br />

      <p>
        Do not resell, barter, trade or otherwise attempt to generate income by
        providing access to your  ZY Adstech Points to others.
      </p>
      <br />

      <p>
        Do not impersonate any individual or entity, including, without
        limitation, a  ZY Adstech employee, agent or client, or other  ZY Adstech
        members or otherwise misrepresent your affiliation with any person or
        entity, including by "framing" any portion of this Site to make it look
        like you have a relationship with us or that we have endorsed you for
        any purpose.
      </p>
      <br />

      <p>
        Do not attempt to circumvent the  ZY Adstech Points system, including by
        requesting rankings from other members or posting content which does not
        respond to or posing any questions in order to garner points.
      </p>
      <br />

      <p>
        Do not publish or make reference in any media to any Survey or Site
        content without our prior written permission.
      </p>
      <br />

      <p>
        Do not collect or store any personal information about other members.
      </p>
      <br />

      <p>
        Do not attempt to access any service or area of the Site that you are
        not authorized to access.
      </p>
      <br />

      <p>
        Do not use any robot, spider, scraper or other automated means or
        interface not provided by us to access the Site or extract data.
      </p>
      <br />

      <p>
        Do not send to or otherwise impact us or this Site (or anything or
        anyone else) with harmful, illegal, deceptive or disruptive code such as
        a virus, "spyware", "adware" or other code that could adversely impact
        this Site or any recipient or take any action, such as a denial of
        service attack, that might impose a significant burden on this Site's
        infrastructure or interfere with the ordinary operation of this Site.
      </p>
      <br />

      <p>
        Do not re-post on the Site any statements you receive from the  ZY Adstech
        help desk.
      </p>
      <br />

      <p>
        Do not communicate with the  ZY Adstech help desk in a manner which is
        obscene, vulgar, harmful, insulting, threatening, abusive, harassing,
        defamatory, libelous, untrue or misleading.
      </p>
      <br />

      <p>
        Do not, under any circumstances, contact  ZY Adstech's clients or the
        sponsor of any survey.
      </p>
      <br />

      <p>
        Do not engage in any other activity that the Company reasonably deems
        improper or abusive.
      </p>
      <br />

      <p>
        Failure to comply with this Code of Conduct may result in termination of
        your membership account and forfeiture of any Points.
      </p>
      <br />

      <p></p>
      <br />

      <p></p>
      <br />

      <p>Uploading content to the Site</p>
      <br />

      <p>
        Whenever you make use of a feature that allows you to upload content to
        our Site, you must comply with the content standards set out in our
        Content Rules (see below).
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Any content you upload to our Site (“User Content”) will be considered
        non-confidential and non-proprietary. You retain all of your ownership
        rights in your User Content, but you grant us and other users of the
        Site (such as our clients) a non-exclusive license to use, store and
        copy that User Content and to distribute and make it available to third
        parties.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        We also have the right to disclose your identity to any third party who
        is claiming that any User Content posted or uploaded by you to our Site
        constitutes a violation of their intellectual property rights, or of
        their right to privacy.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        We will not be responsible, or liable to any third party, for the User
        Content or the accuracy of any User Content posted by you or any other
        user of our Site.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        We have the right to remove any post you make on our Website if, in our
        opinion, your post does not comply with the content standards set out in
        our Content Rules. In particular when posting images please make sure
        that they are either your own images or you have the right to post them,
        for example by using free image sites such as pixabay.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        The views expressed by other users on our Website do not represent our
        views or values.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Rules on uploading your content (including images) (“Content Rules”)
      </p>
      <br />

      <p>
        If you upload any User Content to the Site, you agree to comply with the
        following terms of use at all times:
      </p>
      <br />

      <p></p>
      <br />

      <p>
        You must not post or upload any User Content or use the Site in a way
        that violates any law (including any copyright laws).
      </p>
      <br />

      <p>
        You must not post or upload any personal information about other
        individuals, including any photographs or video footage of them, without
        their full and clear consent for such personal information being (a)
        posted/uploaded to the Site, and (b) used by us and our clients in
        accordance with these Terms (and the Privacy Policy ).
      </p>
      <br />

      <p>
        You must not post or upload any User Content or use the Site in a way
        that is offensive, threatening, defamatory, vulgar, obscene, harassing,
        false, misleading or unreliable, or that brings or is likely to bring
        us, the Site or our clients into disrepute.
      </p>
      <br />

      <p>
        You agree not to contribute User Content that will infringe another
        organization's or person's rights (including intellectual property
        rights and rights of privacy). You agree that you have all the necessary
        rights and permissions to publish the User Content you submit to the
        Site.
      </p>
      <br />

      <p>
        We may moderate and review any User Content that is submitted to be
        posted or is automatically posted/uploaded to the Site. However, we
        accept no responsibility for any User Content and reserve the right to
        remove/edit any User Content at any time.
      </p>
      <br />

      <p>
        You are wholly responsible for all User Content posted by you on the
        Site (including, but not limited to, emails sent by you in connection to
        the Site).
      </p>
      <br />

      <p>
        Please let us know immediately by contacting us  if any of these rules are broken by
        you or other users.
      </p>
      <br />

      <p>
        We reserve the right to remove or edit, or require you to edit, User
        Content posted to the Site at any time.
      </p>
      <br />

      <p>
        Any User Content you post to the Site may be used by us for any purpose
        subject to the Privacy Policy .
      </p>
      <br />

      <p>
        To the extent permitted by law, you hereby indemnify us against all
        losses, damages, proceedings, actions, legal costs and disbursements,
        expenses and any other losses or liabilities arising from your posting
        of User Content, or use of the Site, or your breach of these Terms.
      </p>
      <br />

      <p>
        In the event that we are notified by a third party that User Content
        that you have uploaded to the Site infringes the intellectual property
        rights of a third party, then we reserve the right to release your
        identity and contact details to that third party for the purpose of them
        taking enforcement action against you without notice to you.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        You acknowledge and agree that we can release your details to such third
        parties under any relevant exemptions available to us pursuant to
        applicable data protection legislation for the purposes of that third
        party taking such enforcement action and you hereby waive any rights you
        may have against  ZY Adstech in connection with such disclosure.
      </p>
      <br />

      <p></p>
      <br />

      <p>Confidentiality of Surveys</p>
      <br />

      <p>
         ZY Adstech’s clients may disclose confidential, commercially sensitive
        and/or proprietary information and materials to you as part of Surveys,
        and such information and materials shall remain the sole and exclusive
        property of its owner. This confidential information may include, but is
        not limited to, new services, new product ideas or concepts, packaging
        concepts, advertising and movie or television concepts or trailers, and
        the text, visual images and sounds related thereto. By becoming a
        member, you agree that you will keep the contents and materials
        disclosed to you as part of all Surveys in which you participate
        confidential and not disclose them to any third party or use the
        confidential information for any purpose except for the sole purpose of
        completing the Survey. If you breach this obligation, in addition to
        forfeiture of your Points and termination of your account, you may be
        liable for monetary damages to  ZY Adstech and/or our client for damages
        caused by the result of your breach.
      </p>
      <br />

      <p></p>
      <br />

      <p>Member Content and License</p>
      <br />

      <p>
        You hereby grant a non-exclusive, perpetual, unlimited, worldwide,
        royalty-free license to  ZY Adstech to use for any purpose any content you
        submit to this Site, including the right to edit, copy, transmit,
        publish, display, modify, distribute , create derivative works from and
        develop such content and to transfer such content to third parties. You,
        not  ZY Adstech, will have sole responsibility and liability for all
        content you use, upload, post or submit to the Site, including on our
        message boards and in any Surveys in which you participate, including
        the obligation to obtain any necessary approvals and authorizations
        required to post such content.
      </p>
      <br />

      <p></p>
      <br />

      <p> ZY Adstech Points</p>
      <br />

      <p>What are Points?</p>
      <br />

      <p></p>
      <br />

      <p>
         ZY Adstech values our members' opinions, and to show our appreciation,
         ZY Adstech offers incentives in the form of credits called “Points,” which
        are credited to your account maintained by  ZY Adstech. Points are
        redeemable for cash or rewards as specified and subject to the terms
        below.
      </p>
      <br />

      <p></p>
      <br />

      <p>Earning Points</p>
      <br />

      <p></p>
      <br />

      <p>
        Your ability to earn points is not time-based and we will only award you
        points, which will be deposited into your account in return for your
        participation in activities authorized by  ZY Adstech, which includes, but
        are not limited to, registration as a panelist, completion of
        demographic profiles and completion of qualitative or quantitative
        marketing research activities performed by  ZY Adstech, and only in
        accordance with the specific incentives offered for each activity.
      </p>
      <br />

      <p></p>
      <br />

      <p>
         ZY Adstech is dedicated to providing quality data to our clients. To
        ensure this level of quality, please note that you might not qualify for
        all Surveys, in which case, the Survey will close and you will not
        receive Points. The reasons you might not qualify include, but are not
        limited to:
      </p>
      <br />

      <p></p>
      <br />

      <p>
        You must not post or upload any User Content or use the Site in a way
        that violates any law.
      </p>
      <br />

      <p>Your profile not corresponding to our client's target audience</p>
      <br />

      <p>Discrepancy in logical suite of responses</p>
      <br />

      <p>Untruthful responses or false answers</p>
      <br />

      <p>Inappropriate or abusive replies</p>
      <br />

      <p>
        Going too quickly through the questions to properly consider your
        answers
      </p>
      <br />

      <p>Lapse in attention leading to failed quality checks</p>
      <br />

      <p>You may earn Points as follows:</p>
      <br />

      <p>
        Survey Completion: Earn Points for each Survey you qualify for and
        complete. Points awarded vary by survey.
      </p>
      <br />

      <p>
        Personal Interest Survey ("Profile") Completion: Panelists can earn
        additional Points by completing the Profiles. Each completed Profile is
        worth an additional 100 Points.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        The timing of credits for points depends on the Survey. For some
        Surveys, credits are applied within a few days; for others, the credits
        are applied after the Survey has closed.
      </p>
      <br />

      <p></p>
      <br />

      <p>
         ZY Adstech will maintain a record of the Points credited to and debited
        from your account and you can check the status of your account online by
        logging in and checking the "Your Points" tab. Although  ZY Adstech makes
        every effort to ensure that accounts are credited correctly, it is your
        responsibility to verify that your Points have been credited properly.
        If you feel the incentive amounts credited to your account are
        incorrect, you must contact  ZY Adstech by email within sixty (60) days
        following our alleged error and explain in full the basis of your
        dispute, attaching any relevant information which offers evidence of the
        discrepancy. Upon receipt of your notice, we will investigate your claim
        and notify you of our decision to adjust or maintain the amount of
        Points credited to your account, as we deem appropriate, within thirty
        (30) days. If we need additional time to decide your claim, we will
        notify you and will endeavor to render a decision as soon as reasonably
        practicable. Any decision by us with regard to such a claim will be
        final.
      </p>
      <br />

      <p></p>
      <br />

      <p>Redeeming Points</p>
      <br />

      <p></p>
      <br />

      <p>
        You can spend your Points at any time by going to the "Rewards" tab on
        the main page. Points awarded are not time-based, but are only awarded
        for Survey completion, Profile completion, and other participation on
        this Site.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Points automatically convert to cash values whenever you request a
        payment. In our current model, 3,000 points is equivalent to $1.00.
        Alternatively Points can be converted to a voucher.
      </p>
      <br />

      <p></p>
      <br />

      <p>Vouchers/Coupons</p>
      <br />

      <p></p>
      <br />

      <p>
        If you request a voucher,  ZY Adstech will make the voucher code available
        via electronic means within 3 weeks of your request.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Electronic vouchers (e-vouchers) will either be sent to your  ZY Adstech
        account or to the email address within your member profile in your
        account.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Please make sure that we have your correct email address to avoid any
        delays in delivery.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Any request to resend a missing voucher must be made within 3 months
        from when the voucher was originally sent.  ZY Adstech will not process any
        orders after the expiry of this 3-month time limit.
      </p>
      <br />

      <p></p>
      <br />

      <p>Cash Payments</p>
      <br />

      <p></p>
      <br />

      <p>
        PayPal requests for cash payment will be credited directly to the PayPal
        account indicated in your account at the time within 3 weeks. It is your
        sole responsibility to keep such information current.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        All rewards are subject to change as may be necessary to comply with
        applicable laws or regulations and we reserve the right to voice
        vouchers where such laws or regulations dictate that we must do so.
      </p>
      <br />

      <p></p>
      <br />

      <p>Expiration of Points</p>
      <br />

      <p></p>
      <br />

      <p>
        When Points are credited to your account they will remain valid for one
        year (12 months) from the date they are earned. If after this period the
        Points have not been used, they will automatically expire and be deleted
        from your account. This deduction will always occur towards the end of
        the month, usually on the 28th day. For example, if you earned Points on
        March 15, 2021, they will be removed from your account on March 28,
        2022, if they are still in your account. Points cannot be redeemed after
        the expiration date.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        In addition, all of your Points will immediately expire and be forfeited
        in the event that you cancel your account at  ZY Adstech or if your account
        becomes "inactive." To be an active member means that you have joined
         ZY Adstech and participated in a Survey or other activity on this Site
        within 12 months of your initial registration or within the preceding 12
        months.  ZY Adstech will not provide you with any notice of the
        cancellation and forfeiture of any of your Points.  ZY Adstech reserves the
        right to amend these cancellation and forfeiture rules in its sole
        discretion.
      </p>
      <br />

      <p></p>
      <br />

      <p>Misconduct, Fraud and Correcting Account Errors</p>
      <br />

      <p></p>
      <br />

      <p>
         ZY Adstech retains the right to monitor all member activity within
         ZY Adstech Points. If you have violated our Code of Conduct above, or in
        the event that your account shows signs of fraud, abuse or suspicious
        activity, your member account may be terminated and you may forfeit all
        accumulated Points. If you have conducted any fraudulent activity, then
        subject to local applicable law,  ZY Adstech reserves the right to take any
        necessary legal action and may have grounds to confiscate any rewards
        redeemed as a result of such activity. In addition, if allowed by law,
        you may be liable for monetary losses to  ZY Adstech, including litigation
        costs and damages, and you will not be allowed to participate in
         ZY Adstech Points in the future.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        If your account does not accurately reflect your Point balance,  ZY Adstech
        reserves the right to correct such errors. If you have been awarded
        Points in error or you believe that your member account has been the
        subject of suspicious activity, please contact  ZY Adstech immediately. If
        it is determined that you have been the victim of fraud, the Points you
        have earned will be transferred to a new member account.
      </p>
      <br />

      <p></p>
      <br />

      <p>Transferability</p>
      <br />

      <p></p>
      <br />

      <p>
        Points have no cash value, and may not be assigned, transferred and/or
        pledged to any third party. You have no property rights or other legal
        interests in any Points granted pursuant to the  ZY Adstech Points Program.
      </p>
      <br />

      <p></p>
      <br />

      <p> ZY Adstech Points Program Duration & Changes to Rules</p>
      <br />

      <p></p>
      <br />

      <p>
        Any revisions to the Points Program may affect your ability to use any
        Points you accumulate. If the Points Program is terminated or your
        membership is terminated by  ZY Adstech other than as a result of your
        breach of these Terms, you will only have 30 days from such termination
        date to redeem all your accumulated Points. If there is a modification
        to the Points Program, we will endeavor to notify you; however,  ZY Adstech
        will not be responsible if you do not learn of the modification. The
        rewards offered and Point levels required for specific rewards are
        subject to change without notice. All rewards are subject to
        availability.
      </p>
      <br />

      <p></p>
      <br />

      <p> ZY Adstech Intellectual Property</p>
      <br />

      <p>
        This Site is protected by intellectual property laws and you agree to
        respect them. All rights not expressly granted to you are reserved by
         ZY Adstech or its licensors.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        The " ZY Adstech", " ZY Adstech INFLUENCERS logos and the marks " ZY Adstech",
        " ZY Adstech INFLUENCERS" and " ZY Adstech.com" are trademarks or registered
        trademarks of  ZY Adstech or its affiliates. All other trademarks, service
        marks, logos and trade names used on this Site is the property of
         ZY Adstech or their respective owners or are owned by third parties but
        used by  ZY Adstech with the owner's consent.All rights are reserved.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        For the purposes of this paragraph, "Content" means (without limitation)
        all text, design, graphics, images, sound files, animation, video,
        interfaces, software, code and the selection and arrangement thereof
        appearing or included from time to time on this Site. All Content on
        this Site is subject to intellectual property rights, contractual or
        other protection. The intellectual property rights are owned by us or
        our licensors. No Content may be copied, distributed, republished,
        uploaded, posted or transmitted in any way except pursuant to the
        express provisions of these Terms or with our prior consent.
        Modification or use of the Content for any other purpose may violate
        intellectual property rights. No right to use the Content is granted to
        users and – all titles and/or rights remain with us. This Site is ©
         ZY Adstech. All rights reserved.
      </p>
      <br />

      <p></p>
      <br />

      <p>Disclaimer of Warranties</p>
      <br />

      <p>
        By utilizing the  ZY Adstech Points Program, you acknowledge and agree that
         ZY Adstech is not a bank or any other kind or form of financial
        institution and that "Points" credited to your account are not actual
        cash deposits, do not earn interest, and are only accounts entries that
        entitle you to the amount of the credited Points if you redeem them in
        accordance with these terms of use. You further agree that you may only
        redeem the amounts in your account in the manner and according to the
        procedures described in these terms of use, which we may amend from time
        to time.  ZY Adstech is not responsible for the actions of any third party,
        such as the issuers of any gift cards, vouchers or other rewards
        redeemable for Points.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        You expressly agree that your use of this Site and the  ZY Adstech Points
        Program is at your sole risk. You agree that this Site, all information
        on this Site, the services offered by this Site and the  ZY Adstech Points
        program are provided by  ZY Adstech or its corporate parents or affiliates,
        successors or assigns, suppliers or agents, "AS IS" and "AS AVAILABLE ."
         ZY Adstech is not responsible for typographical errors regardless of
        source. In addition,  ZY Adstech does not represent or warrant that the
        information accessible on this Site is accurate, complete, or current.
        You hereby release  ZY Adstech and its affiliates and third party providers
        from all liability regarding the redemption and use of any rewards,
        including any rewards that, after receipt, may be lost, stolen or
        destroyed.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        TO THE FULLEST EXTENT ALLOWED BY LAW,  ZY Adstech DISCLAIMS ALL WARRANTIES,
        CONDITIONS AND DUTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT
        LIMITED TO ANY IMPLIED WARRANTIES, DUTIES OR CONDITIONS OF: (a)
        MERCHANTABILITY OR SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR
        PURPOSE OR USE, RESULTS, ACCURACY OR COMPLETENESS; AND (b) CREATED BY
        TRADE USAGE, COURSE OF DEALING OR COURSE OF PERFORMANCE.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        YOU ACKNOWLEDGE AND AGREE THAT IF YOU SHARE YOUR PERSONAL DATA,
        INCLUDING IMAGES AND YOUR USER PROFILE INFORMATION WITH OTHER USERS OF
        THE SITE AND/OR PUBLICLY, WE EXCLUDE ALL LIABILITY AS TO THE SECURITY OR
        CONFIDENTIALITY OF SUCH INFORMATION.
      </p>
      <br />

      <p></p>
      <br />

      <p>
         ZY Adstech FURTHER DISCLAIMS ALL DUTIES TO YOU, IF ANY SUCH DUTIES EXIST,
        INCLUDING BUT NOT LIMITED TO REASONABLE CARE, WORKMANLIKE EFFORT,
        FREEDOM FROM COMPUTER VIRUS, AND LACK OF NEGLIGENCE.  ZY Adstech FURTHER
        MAKES NO WARRANTY IN RESPECT OF YOUR ENJOYMENT OF ANY ASPECT OF THE
        SITE.
      </p>
      <br />

      <p></p>
      <br />

      <p>Limitation of Liability</p>
      <br />

      <p>
        TO THE FULLEST EXTENT ALLOWED BY LAW, YOU AGREE THAT NEITHER  ZY Adstech
        NOR ANY OF OUR AFFILIATES OR AGENTS WILL BE LIABLE TO YOU AND/OR ANY
        OTHER PERSON FOR ANY SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR
        SIMILAR GENERAL DAMAGES, OR FOR DAMAGES FOR LOST PROFITS, LOSS OR
        IMPAIRMENT OF PRIVACY, SECURITY OF DATA, FAILURE TO MEET ANY DUTY
        (INCLUDING BUT NOT LIMITED TO ANY DUTY OF GOOD FAITH, WORKMANLIKE EFFORT
        OR OF LACK OF NEGLIGENCE), OR FOR ANY OTHER SIMILAR DAMAGES WHATSOEVER
        THAT ARISE OUT OF OR ARE RELATED TO ANY BREACH OR OTHER ASPECT OF THE
        ENTIRE AGREEMENT OR THIS SITE, EVEN IF  ZY Adstech HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES AND EVEN IN THE EVENT OF FAULT, TORT
        (INCLUDING NEGLIGENCE) OR STRICT OR PRODUCT LIABILITY OR
        MISREPRESENTATION.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        IN NO EVENT SHALL THE LIABILITY OF  ZY Adstech, ITS CORPORATE PARENTS OR
        AFFILIATES, SUCCESSORS OR ASSIGNS, SUPPLIERS OR AGENTS EXCEED THE ACTUAL
        RETAIL VALUE OF AN APPLICABLE REWARD AT ISSUE, OR IF A DISPUTE RELATES
        GENERALLY TO YOUR USE OF THE POINTS PROGRAM, TO THE FAIR MARKET RETAIL
        VALUE OF THE REWARDS WHICH YOUR UNUSED, UNEXPIRED, NON-CANCELED POINTS
        ARE CONVERTIBLE INTO.
      </p>
      <br />

      <p></p>
      <br />

      <p>Indemnification</p>
      <br />

      <p>
        To the fullest extent allowed by law, you agree to indemnify and hold
         ZY Adstech, and its parent and affiliated companies, together with their
        officers, directors, owners, employees and agents harmless from any and
        all claims, losses, damages, suits, fines, levy's and costs (including
        reasonable attorney's fees and expert witness costs, including costs
        associated with in-house counsel), (collectively "Claims"), arising from
        or related to your use of this Site and any content you place on this
        Site, including Claims made by third parties. You agree that  ZY Adstech
        has the unlimited right to defend any claim and settle any claim without
        your prior permission. You agree to provide  ZY Adstech with all reasonable
        assistance in the defense of any claim.
      </p>
      <br />

      <p></p>
      <br />

      <p>Canceling Your Account</p>
      <br />

      <p>
        You may cancel your account at any time by contacting  ZY Adstech at
        https://us. ZY Adstech.com/contactus or by going to “Account details”
        underneath your profile picture and selecting "Unsubscribe Options" on
        the bottom left hand corner, then completing the process by selecting
        the option “cancel your account”. Your account will also be canceled if
        you withdraw from the  ZY Adstech panel. Immediately upon deletion or your
        withdrawal from the  ZY Adstech panel, your account will be closed. You
        understand and agree that, as noted above, upon closing your account,
        your right to access the  ZY Adstech Points program will cease and all
        Points credited to your account at such time, however and whenever
        accumulated, will be forfeited.  ZY Adstech may terminate your account at
        any time for any reason.
      </p>
      <br />

      <p></p>
      <br />

      <p>Tax Liabilities</p>
      <br />

      <p>
        You may be required under local laws to pay taxes on prizes you receive.
        You acknowledge that we may supply information to taxing agencies, or
        withhold taxes, at the request of those agencies or as we, in our sole
        discretion, deem appropriate. You acknowledge and agree that you will
        provide  ZY Adstech with any additional personal information that  ZY Adstech
        needs to comply with any reporting or withholding obligations. Your
        failure to provide such information within 30 days of a request sent by
         ZY Adstech to the email address registered at that time may result in the
        forfeiture of Points or the prizes you may have won.
      </p>
      <br />

      <p></p>
      <br />

      <p>Links</p>
      <br />

      <p>
         ZY Adstech may provide links to third-party websites as a convenience to
        you, and  ZY Adstech's provision of any link does not constitute an
        endorsement by such site of  ZY Adstech or vice versa. You agree that
         ZY Adstech is not responsible for examining or evaluating the content or
        accuracy of these sites and  ZY Adstech does not warrant and will not have
        any liability or responsibility for any third-party materials or
        websites, or for any other materials, products, or services of third
        parties. You agree that you will not use any third-party materials in a
        manner that would infringe or violate the rights of any other party and
        that  ZY Adstech is not in any way responsible for any such use by you.
      </p>
      <br />

      <p></p>
      <br />

      <p>Notices</p>
      <br />

      <p>
        Notices to You: We may give you all notices that we are required to give
        by posting such notices on this Site. You also agree that we may give
        notice by email at our discretion, including notice of subpoenas or
        other legal process (if any). We may provide notice to any email or
        other address that you provide during registration. You agree to keep
        your address current and to check for notices posted on the Site.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Notices to Us: We receive many emails and not all employees are trained
        to deal with every kind of communication, so you agree to send us notice
        by mailing it to  ZY Adstech SAS, 5 avenue du château, 94300 Vincennes,
        France Attn. Team Panels.
      </p>
      <br />

      <p></p>
      <br />

      <p>Entity Agreement; Miscellaneous.</p>
      <br />

      <p>
        These Terms, including items incorporated into them (eg, the Privacy
        Policy), as well as any additional terms or conditions contained on the
        Site for particular activities, and disclosures provided by us and
        consents provided by you on the Site (collectively, the "Entire
        Agreement"), constituting the entire agreement between us and neither
        party has relied on any representations made by the other that are not
        expressly set forth in the Entire Agreement. If any provision of the
        Entire Agreement is found by a court of competent jurisdiction to be
        invalid, illegal or unenforceable it shall be deemed modified to the
        minimum extent necessary to make it valid, legal and enforceable. If
        such modification is not possible, the relevant provision or
        part-provision shall be deemed deleted. Any modification to or deletion
        of a provision or part-provision under shall not affect the validity and
        enforceability of the rest of these Terms and the remaining provisions
        will remain in full force and effect, provided that the allocation of
        risks described herein is given effect to the fullest extent possible.
        Our failure to act with respect to a breach does not waive our right to
        act with respect to subsequent or similar breaches, and time is of the
        essence of the Entire Agreement.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        To the fullest extent allowed under applicable law, you hereby waive
        your right to bring any class action lawsuits against  ZY Adstech with
        respect to any matter. You hereby acknowledge and agree that in the
        event you believe you have any grounds, however so ever arising, to take
        action against  ZY Adstech, such action will be commenced by you alone and
        in your own name.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        This Site is controlled by us from our offices within the United States
        of America and is directed to US users. If you choose to access this
        Site from locations outside the US, you do so at your own risk and you
        are responsible for compliance with applicable local laws. You may not
        use or export anything from the Site in violation of US export laws and
        regulations or the Entire Agreement.
      </p>
      <br />

      <p></p>
      <br />

      <p>Notice of Copyright Infringement</p>
      <br />

      <p>
         ZY Adstech respects the intellectual property rights of others and
        requests that you do the same. Anyone who believes that their work has
        been reproduced on this Site in a way constituting copyright
        infringement may provide a notice to our Group General Counsel
        containing the following:
      </p>
      <br />

      <p></p>
      <br />

      <p>
        An electronic or physical signature of the copyright owner or a person
        authorized to act on behalf of the owner of the copyright
      </p>
      <br />

      <p>
        Identification of the copyrighted work claimed to have been infringed;
      </p>
      <br />

      <p>
        Identification of the material that is claimed to be infringing and
        information sufficient enough to permit us to locate the material;
      </p>
      <br />

      <p>
        The address, telephone number, and, if available, an e-mail address at
        which the complaining party may be contacted;
      </p>
      <br />

      <p>
        A representation that the complaining party has a good faith belief that
        the use of the material in the manner complained of is not authorized by
        the copyright owner, its agent, or the law;
      </p>
      <br />

      <p>
        A representation that the information in the notice is accurate, and if
        applicable that the complaining party is authorized to act on behalf of
        the owner of the right that is allegedly infringed.
      </p>
      <br />

      <p></p>
      <br />

      <p></p>
      <br />

      <p>
        Copyright infringement claims and notices (but not other notices) should
        be sent to the attention of the Group Head of Legal in the following
        manner:
      </p>
      <br />

      <p></p>
      <br />

      <p>
        by mail:  ZY Adstech UK Limited, Ealing Cross, 85 Uxbridge Road, London W5
        5th Attn. Group Head of Legal
      </p>
      <br />

      <p>by email: legal@ ZY Adstech.com</p>
      <br />

      <p></p>
      <br />

      <p>Complaints and Feedback on Surveys</p>
      <br />

      <p>
        If you have a complaint of any nature, or if you have an issue with the
        content of any survey, you should contact us  or at  ZY Adstech SAS, 5 avenue du
        château, 94300, Vincennes, France. In particular, you should not contact
        any of  ZY Adstech's clients or the sponsors of any survey's directly,
        under any circumstances.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        If you have any other complaints, you may contact us at 501 Merritt 7,
        6th Floor, Norwalk, CT 06851, USA Attn. Team Panels. If you are a
        resident of California, you may also reach out to the Complaint
        Assistance Unit of the Division of Consumer Services of the Dept. of
        Consumer Affairs who may be contacted at 400 R Street, Sacramento, CA
        95814 or (800) 952-5210.
      </p>
      <br />

      <p></p>
      <br />

      <p>Release of Liability:</p>
      <br />

      <p>
         ZY Adstech and any partners and clients affiliated with Surveys are not in
        any way liable for lost, late, or misdirected entries or for any damage
        or loss resulting from a) technical errors related to computers,
        servers, providers, or telephone or network lines; b) printing errors;
        or c) injury or damage to property which may be caused, directly or
        indirectly, in whole or in part, from receipt of any prize. Taxes and
        additional shipping, handling and delivery fees in excess of the prize
        amount, if any, are the sole responsibility of the prize winner. By
        accepting the prize, the winner waives the right to assert as a cost of
        winning said prize, any and all costs of redemption or travel to redeem
        said prize and any and all liability that might arise from redeeming or
        seeking said prize.
      </p>
      <br />

      <p></p>
      <br />

      <p>
        Winner also accepts sole responsibility for any miscellaneous costs
        relating to the acceptance of this prize. Except where prohibited,
        acceptance of the prize constitutes the winner's consent to use his or
        her name, likeness, and biographical data for advertising and
        promotional purposes without additional compensation. If the winner does
        not want to authorize the use of his or her name for the likeness of
        advertising and promotional purposes, he or she must inform  ZY Adstech at
        the time of notification.
      </p>
      <br />

      <p></p>
      <br />

      <p>conduct</p>
      <br />

      <p>
         ZY Adstech reserves the right, at its sole discretion, to terminate a
        member account of any individual it finds to be: a) tampering or
        attempting to tamper with any  ZY Adstech website; b) violating the terms
        of service, conditions of use and/or general rules or guidelines of any
         ZY Adstech property or service; or c) acting in an unsportsmanlike or
        disruptive manner, or with the intent to annoy, abuse, threaten or
        harass any other person.
      </p>
      <br />

      <p></p>
      <br />

      <p>Termination of your account</p>
      <br />

      <p>
        If your membership or your access to the Site is terminated or suspended
        for any reason, you must not access the Website without our prior
        written consent.
      </p>
      <br />
    </div>
  </BasePage>
</template>

<script setup>
import { reactive } from "vue";
import BasePage from "@/components/BasePage/index.vue";

const data = reactive({});
</script>

<style scoped lang="less">
@import "./index.less";
</style>